window.inbev ||= {}

inbev.block_map =
  api_key: "AIzaSyBLWBv14ltLNWjqcFO454v7Aw0AVLf0yWM"
  map_elements: document.querySelectorAll('.content_block .map_element')
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#7c6e49"
        }
      ]
    },
    {
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "lightness": 75
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 65
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "color": "#7c6e49"
        },
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f4ef"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]

  setup: ->
    if inbev.block_map.map_elements.length == 0
      return false

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{inbev.block_map.api_key}&callback=inbev.block_map.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        inbev.block_map.initialize()
    )(document,"script","gmap_loader")

  initialize: ->
    @setup_maps()

  setup_maps: ->
    inbev.block_map.map_elements.forEach (location, idx) ->
      map = inbev.block_map.create_map(location)

      google.maps.event.addListenerOnce map, 'idle', ->
        if window.innerWidth > 1023
          map.panBy(-300, 0)

      icon = location.getAttribute('data-icon')

      marker = new google.maps.Marker({
        position: { lat: parseFloat(location.getAttribute('data-lat')), lng: parseFloat(location.getAttribute('data-lng')) },
        icon: icon,
        map: map
      })

      url = location.getAttribute('data-url')
      if url
        marker.addListener 'click', ->
          window.location.href = url

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      zoom: 6
      mapTypeId: google.maps.MapTypeId.ROADMAP
      draggable: false
      cameraControl: false
      zoomControl: false
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false
      styles: inbev.block_map.styles

    if window.innerWidth > 1023
      options['zoom'] = 7

    new google.maps.Map(location, options)

